module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PWA Shields","short_name":"PWA Shields","start_url":"/","background_color":"#353A3F","theme_color":"#353A3F","display":"standalone","icon":"static/images/favicon.svg","description":"Personalize your app's README with custom, fun, PWA shields in SVG","features":["Create & customize shields","View documentation","No network connection needed"],"screenshots":[{"src":"https://www.pwa-shields.com/images/screenshots/home-screen.png"},{"src":"https://www.pwa-shields.com/images/screenshots/series.png"},{"src":"https://www.pwa-shields.com/images/screenshots/create.png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4c70a0aae4707129efc68b2a6d176bb5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-K9ZMDNM4F7","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WF9BBDH","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    }]
